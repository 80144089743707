import { SliderShadowBox as SliderShadowBoxTemplate } from '@/organisms';

import type { TypeSliderShadowBoxProps } from '@/organisms/slider-shadow-box';

const SliderShadowBox = ({
  id,
  bloque,
  className,
}: {
  id: string;
  bloque: {
    fieldGroupName: string;
    cards: TypeSliderShadowBoxProps[];
  };
  className?: string;
}) => (
  <section id={id} className={`px-4 py-4 md:py-8 ${className}`}>
    <div className="u-wrapper flex flex-col gap-4">
      <SliderShadowBoxTemplate {...bloque} />
    </div>
  </section>
);

SliderShadowBox.displayName = 'SliderShadowBox';
export default SliderShadowBox;
